import httpClient  from '.';
const API_CONTROLLER = 'report-customer/';

export default {
    getReportSummaryByCustomer(search) {
        let url = API_CONTROLLER + 'get-summary-by-customer';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                branchId: search.branchId,
                customerId: search.customerId
            }
        });
    },
}