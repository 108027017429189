<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Báo cáo tổng hợp theo khách hàng</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="openSearch()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div style="overflow: auto; width: 100%;">
                        <table class="data-table" style="width: 100% !important;">
                            <thead>
                                <tr>
                                    <th style="width:50px;" rowspan="2">#</th>
                                    <th style="width:150px;" rowspan="2">Khách hàng</th>
                                    <th style="width:100px;" rowspan="2">Số đơn hàng</th>
                                    <th style="width:150px; background-color: #9DDE8B;" colspan="3">Tổng hợp</th>
                                    <th style="width:360px; background-color: #6DC5D1;" colspan="3">Thu khách hàng</th>
                                    <th style="width:360px; background-color: #FF9F66;" colspan="10">Chi phí</th>
                                    <th style="width:360px; background-color: #F1EF99;" colspan="3">Chi phí Vendor</th>
                                </tr>
                                <tr>
                                    <th style="width:120px; background-color: #9DDE8B;">Doanh thu</th>
                                    <th style="width:120px; background-color: #9DDE8B;">Chi phí</th>
                                    <th style="width:150px; background-color: #9DDE8B;">Lợi nhuận</th>
                                    
                                    <th style="width:150px; background-color: #6DC5D1;">Cước vận chuyển</th>
                                    <th style="width:120px; background-color: #6DC5D1;">Phí dịch vụ</th>
                                    <th style="width:120px; background-color: #6DC5D1;">Chi hộ</th>
                                    
                                    <th style="width:120px; background-color: #FF9F66;">Chi phí làm hàng</th>
                                    <th style="width:120px; background-color: #FF9F66;">Chi hộ làm hàng</th>
                                    <th style="width:120px; background-color: #FF9F66;">Phí dịch vụ làm hàng</th>

                                    <th style="width:120px; background-color: #FF9F66;">Chi phí cố định</th>
                                    <th style="width:120px; background-color: #FF9F66;">Chi phí điều xe</th>
                                    <th style="width:120px; background-color: #FF9F66;">Lương lái xe</th>
                                    <th style="width:120px; background-color: #FF9F66;">Phí VETC</th>
                                    <th style="width:120px; background-color: #FF9F66;">Chi phí dầu</th>
                                    <th style="width:120px; background-color: #FF9F66;">Chi hộ điều xe</th>
                                    <th style="width:120px; background-color: #FF9F66;">Phí dịch vụ điều xe</th>

                                    <th style="width:120px; background-color: #F1EF99;">Cước vận chuyển</th>
                                    <th style="width:120px; background-color: #F1EF99;">Chi hộ </th>
                                    <th style="width:120px; background-color: #F1EF99;">Phí dịch vụ</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="left">{{item.customerAlias}}</td>
                                    <td class="center">{{item.numberOrder}}</td>
                                    
                                    <td class="right" style="color: #478CCF; font-weight: bold;">{{formatNumber(item.totalRevenue)}}</td>
                                    <td class="right" style="color: red; font-weight: bold;">{{formatNumber(item.totalExpense)}}</td>
                                    <td class="right">
                                        <span v-if="item.profit > 0" style="color: #399918; font-weight: bold;">{{formatNumber(item.profit) + ' (' + formatNumber(item.pctProfit) + '%)'}}</span>
                                        <span v-else style="color: red; font-weight: bold;">{{formatNumber(item.profit) + ' (' + formatNumber(item.pctProfit) + '%)'}}</span>
                                    </td>
                                    
                                    <td class="right">{{formatNumber(item.revenueTransport)}}</td>
                                    <td class="right">{{formatNumber(item.revenueService)}}</td>
                                    <td class="right">{{formatNumber(item.revenuePayOnBehalf)}}</td>

                                    <td class="right">{{formatNumber(item.handleFee)}}</td>
                                    <td class="right">{{formatNumber(item.handlePayOnBehalf)}}</td>
                                    <td class="right">{{formatNumber(item.handleService)}}</td>

                                    <td class="right">{{formatNumber(item.fixedCost)}}</td>
                                    <td class="right">{{formatNumber(item.jobFee)}}</td>
                                    <td class="right">{{formatNumber(item.jobSalary)}}</td>
                                    <td class="right">{{formatNumber(item.jobVetc)}}</td>
                                    <td class="right">{{formatNumber(item.jobFuel)}}</td>
                                    <td class="right">{{formatNumber(item.jobPayOnBehalf)}}</td>
                                    <td class="right">{{formatNumber(item.jobService)}}</td>

                                    <td class="right">{{formatNumber(item.vendorTransport)}}</td>
                                    <td class="right">{{formatNumber(item.vendorPayOnBehalf)}}</td>
                                    <td class="right">{{formatNumber(item.vendorService)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                 <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <reportDetailForm ref="reportDetailForm" />
    </div>
</template>
<script>
    import reportCustomerService from '../../../api/reportCustomerService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import branchService from '../../../api/branchService';
    import companyService from '../../../api/companyService';
    import reportDetailForm from './ReportOrderDetail.vue'
    import customerList from '../../../components/popup/_CompanyList.vue';

    export default {
        components: {
            reportDetailForm,
            customerList
        },
        metaInfo: {
            title: 'Báo cáo tổng hợp theo khách hàng'
        },
        data() {
            return {
                loadding: false,
                search: { pageIndex: 1, pageSize: common.pageSize, branchId: 0, customerId: 0 },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                customerType: common.companyType.customer,
                branchs: [],
                customerName: '',
                customers: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.getBranchs();
            this.filter();
        },
        mounted() {
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            open(id){
                this.$refs.reportDetailForm.open(id);
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search = { pageIndex: 1, pageSize: common.pageSize, branchId: 0, customerId: 0 };
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/report-summary-customer?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                reportCustomerService.getReportSummaryByCustomer(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs.push({ id: 0, branchName: 'Tất cả chi nhánh' });
                        response.data.map(item => {
                            this.branchs.push({ id: item.id, branchName: item.branchName });
                        })
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.branchId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.customerId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
