<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content style="width: 1200px;">
            <div class="page-content-1" style="height: 95vh; overflow: auto">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>THỐNG KÊ CHI TIẾT CHI PHÍ ĐƠN HÀNG</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="box">
                                <table style="min-height: 100px !important;">
                                    <thead>
                                        <tr>
                                            <th style="width: 1200px;" colspan="12">THỐNG KÊ ĐƠN HÀNG</th>
                                        </tr>
                                        <tr>
                                            <th style="background-color: #9DDE8B; width: 50vw;" colspan="3">Tổng hợp</th>
                                            <th style="background-color: #36C2CE; width: 50vw;" colspan="4">Thu khách hàng</th>
                                            <th style="background-color: #FFB22C; width: 50vw;" colspan="8">Chi phí</th>
                                        </tr>
                                        <tr>
                                            <th style="background-color: #9DDE8B;">Thu KH</th>
                                            <th style="background-color: #9DDE8B;">Chi phí</th>
                                            <th style="background-color: #9DDE8B;">Lợi nhuận</th>

                                            <th style="background-color: #36C2CE;">Cước vận chuyển</th>
                                            <th style="background-color: #36C2CE;">Chi hộ</th>
                                            <th style="background-color: #36C2CE;">Phí dịch vụ</th>
                                            <th style="background-color: #36C2CE;">Tổng thu</th>

                                            <th style="background-color: #FFB22C;">Chi phí cố định</th>
                                            <th style="background-color: #FFB22C;">Cước vận chuyển Vendor</th>
                                            <th style="background-color: #FFB22C;">Chi hộ</th>
                                            <th style="background-color: #FFB22C;">Phí dịch vụ</th>
                                            <th style="background-color: #FFB22C;">Phí VETC</th>
                                            <th style="background-color: #FFB22C;">Chi phí dầu</th>

                                            <th style="background-color: #FFB22C;">Lương lái xe</th>
                                            <th style="background-color: #FFB22C;">Tổng chi phí</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="right" style="color: #FFB22C; font-weight: bold;">{{ formatNumber(data.totalRevenue) }}</td>
                                            <td class="right" style="color: red; font-weight: bold;">{{ formatNumber(data.totalExpense) }}</td>
                                            <td class="right" style="font-weight: bold;">
                                                <span v-if="data.profit > 0" style="color: #06D001;">{{ formatNumber(data.profit)+ ' (' + formatNumber(data.pctProfit) + "%)" }}</span>
                                                <span v-else style="color: red;">{{ formatNumber(data.profit) + ' (' + formatNumber(data.pctProfit) + "%)" }}</span>
                                            </td>
                                            
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.revenue.totalTransportCost) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.revenue.totalOnBehalf) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.revenue.totalService) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.revenue.totalRevenue) }}</td>

                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.totalFixedCost) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.expense.totalTransportCost) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.expense.totalOnBehalf) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.expense.totalService) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.expense.totalVetc) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.totalFuel) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.expense.totalSalary) }}</td>
                                            <td class="right" style="font-weight: bold;">{{ formatNumber(data.expense.totalExpense) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-revenue" class="tab-content" md-label="Thu khách hàng">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="list" style="overflow-x: auto; width: 100%;">
                                                            <table class="data-table-2" style="width: 1420px !important;"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th class="center" style="width:50px;">Stt</th>
                                                                        <th class="left" style="width:150px;">Loại hình</th>
                                                                        <th class="left" style="width:150px;">Loại hàng</th>
                                                                        <th class="center" style="width:120px;">Đơn vị</th>
                                                                        <th class="left" style="width:250px;">Tên phí</th>
                                                                        <th class="left" style="width:150px;">Loại phí</th>
                                                                        <th style="width:100px;">Số lượng</th>
                                                                        <th style="width:150px;">Đơn giá</th>
                                                                        <th style="width:150px;">VAT</th>
                                                                        <th style="width:150px;">Thành tiền</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false && data.revenueList.length == 0" style="height: 100px;"> 
                                                                    <tr><td colspan="100" class="no-data">Không có dữ liệu</td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false && data.revenueList.length > 0"> 
                                                                    <tr v-for="(item, index) in data.revenueList" :key="'revenue-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="center">{{index + 1}}</td>
                                                                        <td class="left">
                                                                            {{ getTypeOfTransport(item.typeOfTransport) }}
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ getGoodsType(item.goodsTypeId) }}
                                                                        </td>
                                                                        <td class="center">
                                                                            {{ item.unitName }}
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ item.feeName }}
                                                                        </td> 
                                                                        <td class="left">
                                                                            {{ getFeeType(item.type) }}
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.quantity) }}
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.price) }}
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.vat) }}%
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.amount) }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                        <md-tab id="tab-expense" class="tab-content" md-label="Chi phí">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="list" style="overflow-x: auto; width: 100%;">
                                                            <table class="data-table-2" style="width: 1420px !important;"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th class="center" style="width:50px;">Stt</th>
                                                                        <th class="left" style="width:150px;">Loại hình</th>
                                                                        <th class="left" style="width:150px;">Loại hàng</th>
                                                                        <th class="center" style="width:120px;">Đơn vị</th>
                                                                        <th class="left" style="width:250px;">Tên phí</th>
                                                                        <th class="left" style="width:150px;">Loại phí</th>
                                                                        <th style="width:100px;">Số lượng</th>
                                                                        <th style="width:150px;">Đơn giá</th>
                                                                        <th style="width:150px;">VAT</th>
                                                                        <th style="width:150px;">Thành tiền</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false && data.expenseList.length == 0" style="height: 100px;"> 
                                                                    <tr><td colspan="100" class="no-data">Không có dữ liệu</td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false && data.expenseList.length > 0"> 
                                                                    <tr v-for="(item, index) in data.expenseList" :key="'revenue-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="center">{{index + 1}}</td>
                                                                        <td class="left">
                                                                            {{ getTypeOfTransport(item.typeOfTransport) }}
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ getGoodsType(item.goodsTypeId) }}
                                                                        </td>
                                                                        <td class="center">
                                                                            {{ item.unitName }}
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ item.feeName }}
                                                                        </td> 
                                                                        <td class="left">
                                                                            {{ getFeeType(item.type) }}
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.quantity) }}
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.price) }}
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.vat) }}%
                                                                        </td>
                                                                        <td class="right">
                                                                            {{ formatNumber(item.amount) }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import reportOrderService from '../../../api/reportOrderService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        data() {
            return {
                showDialog: false,
                loading: false,
                data: { 
                    profit: 0,
                    totalRevenue: 0,
                    totalExpense: 0,
                    pctProfit: 0,
                    revenue: {
                        totalRevenue: 0,
                        totalTransportCost: 0,
                        totalOnBehalf: 0,
                        totalService: 0,
                    },
                    expense: {
                        totalExpense: 0,
                        totalTransportCost: 0,
                        totalOnBehalf: 0,
                        totalService: 0,
                        totalSalary: 0,
                        totalVetc: 0,
                        totalFee: 0,
                    },
                    revenueList: [], 
                    expenseList: []  
                },
                orderId: 0
            }
        },
        created(){
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            open(orderId){
                this.orderId = orderId;
                this.getData()
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            getData(){
                this.setLoading(true);
                reportOrderService.getStatisticByOrder(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        }
    }
</script>

<style scoped>
    .box {
        padding: 10px 0;
    }
</style>

